@import "../variables/variables.less";
@import "footer_extras.less";
@import "legal_notice.less";
@import "reinsurances.less";
@import "secure_payment.less";
@import "seo.less";

.footer {
    background: var(--white);
    margin: 0 -16px;

    .copyright {
        font-size: 13px;
        margin-right: auto;
    }
}
