@import "./toast.less";
@import "./comparator_button.less";

.comparator {
    &.js-active {
        display: block;
    }

    .wrapper {
        position: relative;
        background-color: var(--blue);
        padding: 12px;
        color: var(--white);
        width: 1024px;
        margin: 0 auto;

        &::before {
            content: "";
            display: block;
            position: absolute;
            inset: 0;
            background-color: var(--blue);
            margin: 0 -100%;
        }

        @media @desktop {
            width: 1280px;
        }

        @media @desktopXL {
            width: 1600px;
        }
    }

    .sticky {
        position: relative;
        display: flex;
        justify-content: center;
        height: 41px;
    }

    .actions {
        position: absolute;
        left: 0;
        display: flex;
        flex-flow: row nowrap;
        gap: 16px;
    }

    .toggle {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        gap: 4px;
        font-family: var(--font-regular);
        font-size: 14px;
        color: var(--white);
        margin: 0 auto;
        cursor: pointer;

        &::after {
            content: "";
            display: block;
            background-image: url(../../../version_common/styles/images/pictos/arrow_right_white.svg);
            background-size: 80%;
            background-position: center;
            background-repeat: no-repeat;
            width: 10px;
            height: 16px;
            object-fit: contain;
            transform: rotate(270deg);
            margin-left: 4px;
        }
    }

    &-component {
        display: none;
        position: fixed;
        inset: auto 0 -412px;
        z-index: 10;

        &.js-open {
            inset: auto 0 0;

            .toggle::after {
                transform: rotate(90deg);
            }
        }
    }

    .comparator-list {
        position: relative;
        height: 412px;
        width: 1024px;
        background-color: var(--white);
        margin: 0 auto;

        &::before {
            content: "";
            display: block;
            position: absolute;
            inset: 0;
            background-color: var(--white);
            margin: 0 -100%;
        }

        @media @desktop {
            width: 1280px;
        }

        @media @desktopXL {
            width: 1600px;
        }

        .splide__wrapper {
            margin: 0;
            padding: 24px;
        }

        .splide__slide {
            border: solid 1px var(--low-grey);
            border-radius: 3px;

            &.dashed {
                border-style: dashed;
            }
        }

        .product-close {
            position: absolute;
            top: 13px;
            right: 13px;
            display: inline-block;
            border-radius: 3px;
            z-index: 100;
            cursor: pointer;
        }

        .product-placeholder {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 254px;
            padding: 16px;
            font-size: 50px;
            color: var(--low-grey);
        }

        .loader {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            &::before {
                content: "";
                height: 50px;
                width: 50px;
                display: flex;
                align-items: center;
                margin: 0 auto;
                background-image: url(../../../version_common/styles/images/loader-blue.gif);
                background-repeat: no-repeat;
                background-position: center;
                background-size: 50px;
                z-index: 10;
            }
        }
    }
}
