@import "../variables/pictos_variables.less";

.picto {
    display: inline-block;

    &.eu,
    &.fr,
    &.plus,
    &.mkp {
        height: 16px;
        min-width: 16px;
    }

    &.info {
        height: 17px;
        min-width: 17px;
        background-image: @picto_info;
    }

    &.info_light {
        height: 16px;
        width: 16px;
        background-image: @picto_info_light;
    }

    &.info_dark {
        height: 16px;
        width: 16px;
        background-image: @picto_info_dark;
    }

    &.mkp {
        background-image: @picto_mkp;
    }

    &.eu {
        background-image: @picto_round_europe;
    }

    &.fr {
        background-image: @picto_round_france;
    }

    &.cart-plus {
        height: 24px;
        min-width: 24px;
        background-image: @cart_plus;
    }

    &.plus {
        background-image: @plus;
    }
}
