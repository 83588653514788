.inner-links-with-banner {
    margin: 20px 0;
}

.bloc_inner_links {
    .inner_header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &-link {
            text-align: right;
            color: var(--dark-grey);
            margin-left: auto;
            margin-right: 35px;
        }
    }
}

.sublinks-list {
    margin: 0 auto;
    padding: 15px 8px;
    text-align: left;
    width: calc(100% - 16px);

    .sublink-item {
        padding: 0 0 14px;

        &:last-child {
            padding: 0;
        }
    }

    .sublink {
        font-size: 12px;
        color: var(--dark-grey);
        text-decoration: none;
        line-height: 1.2;

        &:hover {
            text-decoration: underline;
        }
    }
}

.slider-inner-links {
    .splide__slide {
        border-radius: 3px;
        display: block;

        &.black_friday {
            color: var(--white);
            background-color: var(--dark-grey);
        }

        &.bon_plan {
            color: var(--white);
            background-color: var(--red);
        }

        &.brand_operation {
            color: var(--dark-grey);
            background-color: var(--message);
        }
    }

    .link {
        color: inherit;
        text-decoration: none;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
        }
    }

    .title {
        font-family: var(--font-bold);
        font-size: 16px;
        line-height: 20px;
        margin-top: 8px;

        &.with-sublink {
            min-height: 40px;
        }
    }

    .picture {
        display: block;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
        margin: auto;

        &_wrapper {
            height: 118px;
            display: flex;
            margin: 0 auto;
            background-color: var(--light-grey);
            border-radius: 3px;
            overflow: hidden;
        }
    }

    .picture_wrapper.link {
        padding: 0;
    }

    .top-wrapper {
        padding: 8px;
        position: relative;

        &--has-title + .sublinks-list {
            border-top: 1px solid var(--border-grey);
        }
    }
}

.wrapper-inner-links {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    .splide {
        &__wrapper {
            width: 522px;
            margin-left: -140px;

            @media @desktop {
                width: 544px;
                margin-left: -66px;
            }

            @media @desktopXL {
                width: 744px;
                margin-left: -47px;
            }
        }

        .top-wrapper {
            padding: 22px 8px 16px;

            @media @desktop { padding-top: 26px; }

            @media @desktopXL { padding-top: 16px; }
        }
    }

    .image {
        display: block;
        width: 614px;
        height: auto;

        @media @desktop { width: 774px; }

        @media @desktopXL { width: 875px; }
    }

    .picture {
        margin: auto;

        &_wrapper {
            height: 208px;
            width: 208px;
            display: flex;
            margin: 0 auto;
            background-color: var(--light-grey);
        }
    }
}

.slider-inner-links.is-big-layout {
    .splide__slide { height: 218px; }

    .picture_wrapper { height: 150px; }
}
