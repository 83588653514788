@default-margin-bottom: 12px;

.modal-title,
.section-title {
    font-family: var(--font-bold);
    font-size: 18px;
    line-height: 1.28;
}

.section-title {
    margin-bottom: 16px;
}

.splide__wrapper .section-title {
    margin-bottom: @default-margin-bottom;
}

.seo_links_title_associate.section-title {
    margin-bottom: @default-margin-bottom;
}
