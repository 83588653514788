.ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ellipsis-1line {
    display: block;
    white-space: nowrap;
}

.ellipsis-2lines {
    -webkit-line-clamp: 2;
}

.ellipsis-3lines {
    -webkit-line-clamp: 3;
}

.ellipsis-4lines {
    -webkit-line-clamp: 4;
}

.text-blue {
    color: var(--blue);
}

.text-bold {
    font-family: var(--font-bold);
}

.text-normal {
    font-family: var(--font-regular);
}
