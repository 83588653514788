.modal {
    .overlay {
        position: fixed;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--overlay);
        z-index: 11;
    }

    .container {
        position: relative;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        padding: 24px;
        width: 600px;
        border-radius: 3px;
        background-color: var(--white);
    }

    .main {
        max-height: 275px;
        font-size: 14px;
        line-height: 1.3;
        margin-right: -12px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            width: 8px;
        }

        &::-webkit-scrollbar-track {
            display: none;
        }

        &::-webkit-scrollbar-thumb {
            background: var(--border-grey);
            border-radius: 4px;
        }
    }

    .header + .main {
        margin-top: 18px;
    }

    .title {
        padding-bottom: 10px;
        border-bottom: 1px solid var(--border-grey);
    }

    .close {
        position: absolute;
        top: -24px;
        right: 4px;
        width: 16px;
        height: 16px;
        border: 0;
        background: url(../../../version_common/styles/images/pictos/picto_close_white.svg) center/16px;
        cursor: pointer;
    }

    .footer {
        display: flex;
    }

    .link {
        margin-top: auto;
        font-size: 12px;
        text-decoration: underline;
        color: var(--blue);
    }

    .btn {
        align-self: flex-end;
        padding: 10px 46px 12px;
        margin-left: auto;
        border-style: none;
        border-radius: 3px;
        font-size: 14px;
        background-color: var(--blue);
        color: var(--white);
    }

    .paragraph {
        margin-bottom: 14px;
    }
}

.micromodal-slide {
    display: none;

    &.is-open {
        display: block;
    }
}

.geoloc_popin,
.add-deliverable-popin {
    z-index: 11;

    .searchbar {
        margin: 0;
    }

    .request.error {
        display: none;
        color: var(--red);
        margin-top: 8px;

        &.js-active {
            display: block;
        }
    }

    .action {
        display: none;
        justify-content: center;
        align-items: center;
        margin-top: 8px;
        
        .btn {
            margin: auto;
            cursor: pointer;
            background-color: var(--red);
        };
    }

    .status {
        display: none;
        margin-top: 8px;

        &.js-valid,
        &.js-invalid {
            display: block;
        }
    
        &.js-valid {
            color: var(--green);

            + .action {
                display: flex;
            }
        }
    
        &.js-invalid {
            color: var(--red);
        }
    }

    .geoloc_footer {
        display: flex;
        justify-content: center;
        margin-top: 24px;
        font-size: 12px;
        color: var(--soft-grey);
    }
}

.add-clickcollect-popin {
    &.modal {
        .request.error {
            display: none;
            color: var(--red);

            &.js-active {
                display: block;
            }
        }

        .searchbar {
            display: flex;
            flex-flow: row nowrap;
            margin: 0 0 12px;

            .form {
                flex: 1;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            .submit {
                background-color: var(--black);
                color: var(--white);
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }

            + .searchbar_error {
                font-family: var(--font-bold);
                color: var(--red);
            }
        }

        .main {
            max-height: 590px;
            overflow: inherit;
            overflow-y: auto;
        }

        .click-collect-logo {
            display: block;
            margin: 0 auto 16px;
        }

        .click-collect-iframe {
            width: 100%;
            height: 500px;
        }

        .message-durable-wrap {
            margin: 16px 68px 16px 0;
            background-color: var(--light-grey);
            padding: 0 10px 0 80px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            position: relative;
            margin-left: -24px;
            height: 67px;
            border-top-right-radius: 67px;
            border-bottom-right-radius: 67px;
        }

        .message-durable {
            font-size: 14px;
            line-height: 18.5px;
            flex: 1;
        }

        .message-picto-wrap {
            background: url(../../../version_common/styles/images/pictos/darty_eco.svg) var(--white) no-repeat center;
            background-size: 70%;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            display: flex;
            margin-left: 4px;
        }

        .extras {
            display: flex;
            flex-flow: column;
            gap: 4px;
            margin-top: 16px;

            .link {
                color: inherit;
            }
        }
    }
}

.add-deliverable-popin.modal {
    .warehouse-popin-input {
        width: 100%;
        height: 34px;
        margin-top: 22px;
        border-radius: 5px;
        background-color: var(--light-grey);
        text-indent: 15px;
        border: 1px solid var(--low-grey);

        &:focus {
            outline: var(--grey);
        }
    }

    .warehouse-suggestions {
        width: 80%;

        .autocomplete-suggestions {
            z-index: 10;
        }

        .ui-menu-item {
            padding: 10px 16px;
            font-size: 12px;
        }
    }

    .warehouse-error,
    .warehouse-success {
        display: none;
        margin: 5px 0;
    }

    .warehouse-error {
        font-family: var(--font-bold);
        color: var(--red);

        .availability-link {
            color: var(--red);
        }
    }

    .warehouse-success {
        text-align: center;
        color: var(--green);

        .text {
            text-align: left;
        }
    }

    .add-continue {
        width: 200px;
        height: 40px;
        margin-top: 25px;
        background-color: var(--red);
        border-radius: 3px;
        border: 0;
        color: var(--white);
        font-size: 10px;
        text-transform: uppercase;
        line-height: 40px;
        cursor: pointer;
    }

    .warehouse-link-footer {
        position: absolute;
        bottom: 10px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 12px;
        color: var(--soft-grey);
    }
}

.deliverable_js-hidden {
    display: none;
}

.deliverable_js-visible {
    display: block;
}

.durability_popin {
    .container {
        width: 900px;
    }

    .picture {
        width: 100%;
        height: auto;
    }
}

.logistics_popin {
    .error {
        margin: 16px 0;
        color: var(--red);
    }

    .geoloc_footer {
        display: flex;
        justify-content: center;
        margin-top: 24px;
        font-size: 12px;
        color: var(--soft-grey);
    }
}

[data-micromodal-trigger] {
    cursor: pointer;
}
