[data-tooltip],
.tooltip-wrap {
    position: relative;
    display: inline-block;
    cursor: pointer;
    filter: drop-shadow(0 0 10px rgba(69, 69, 69, .08));
    height: 15px;
    width: 15px;

    &.bottom {
        &::after {
            top: 22px;
        }

        .tooltip,
        &::before {
            top: 33px;
            bottom: unset;
        }
    }

    &.left {
        .tooltip,
        &::before {
            transform: translateX(-85%);
        }
    }

    &::after {
        display: none;
        content: "";
        position: absolute;
        left: 50%;
        top: -26px;
        bottom: -6px;
        transform: translateX(-50%) rotate(45deg);
        height: 24px;
        width: 24px;
        background: @tooltipSquare no-repeat center;
        background-size: 50%;
    }

    .tooltip .link {
        color: inherit;
    }

    .tooltip,
    &::before {
        display: none;
        content: attr(data-tooltip);
        position: absolute;
        bottom: 30px;
        left: calc(50% - (250/2));
        transform: translateX(-50%);
        width: 250px;
        padding: 13px 16px;
        border-radius: 3px;
        background-color: var(--white);
        font-family: var(--font-regular);
        color: var(--dark-grey);
        font-size: 14px;
        line-height: 18px;
    }

    &:hover {
        .tooltip,
        &::before,
        &::after {
            display: block;
        }
    }

    .bottom {
        &::before,
        &::after {
            top: 100%;
            bottom: auto;
            margin-top: 10px;
        }

        &::after {
            transform: translateX(-50%) rotate(-135deg);
        }
    }
}
