.button-comparator {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 8px;
    font-family: var(--font-regular);
    font-size: 14px;
    line-height: 18px;
    color: inherit;
    text-decoration: none;
    margin-top: 16px;
    cursor: pointer;

    .checkbox {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        border: solid 2px var(--low-grey);
        border-radius: 4px;
        background-color: var(--white);
        flex: none;
    }

    .text::after {
        display: inline;
        content: attr(data-unselected);
    }

    &.selected {
        .text::after {
            content: attr(data-selected);
        }

        .checkbox {
            background-color: var(--blue);
            border-color: var(--blue);

            &::after {
                content: "";
                height: 20px;
                width: 20px;
                background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Im05Ljc1NTIgMTUuMDU1LTMuODQ5Mi0zLjk3MmMtMC40MjIzOC0wLjQzNTktMS4xMTgxLTAuNDQ2OC0xLjU1NC0wLjAyNDQtMC4wMDczMyAwLjAwNzEtMC4wMTQ1NiAwLjAxNDMtMC4wMjE2OSAwLjAyMTYtMC40Mzk0MiAwLjQ1MDMtMC40NDA2OSAxLjE2ODQtMC4wMDI4NyAxLjYyMDJsNS40Mjc3IDUuNjAwOSA5LjkxOTktMTAuMzQ5YzAuNDM0OS0wLjQ1Mzc3IDAuNDMzLTEuMTcwMy0wLjAwNDUtMS42MjE3LTAuNDE3LTAuNDMwMzItMS4xMDM5LTAuNDQxMTEtMS41MzQyLTAuMDI0MDktMC4wMDk3IDAuMDA5MzctMC4wMTkyIDAuMDE4OTEtMC4wMjg1IDAuMDI4NjNsLTguMzUyNyA4LjcyMDF6IiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGw9IiNmZmYiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4K) no-repeat 50% 50%;
            }
        }
    }

    &:hover .checkbox {
        border-color: var(--blue);
    }
}
