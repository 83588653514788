@value : 16px;

.mr {
    margin-right: @value;
}

.ml {
    margin-left: @value;
}

.mt {
    margin-top: @value;
}

.mb {
    margin-bottom: @value;
}

.pr {
    padding-right: @value;
}

.pl {
    padding-left: @value;
}

.pt {
    padding-top: @value;
}

.pb {
    padding-bottom: @value;
}
