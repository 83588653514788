.section-alt {
    position: relative;
    margin-bottom: 24px;

    .btn-alt {
        display: none;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        margin-top: 4px;
        background: transparent;
        font-size: 14px;
        font-family: var(--font-bold);
        color: var(--blue);
        padding: 30px 15px 15px;
        border: none;
        z-index: 10;
        cursor: pointer;

        .label {
            display: flex;
            align-items: center;
            position: relative;

            &::after {
                content: "";
                display: inline-block;
                width: 26px;
                height: 26px;
                background: url(../../../version_common/images/pictos/arrow-blue-down.svg) no-repeat right -2px;
                background-size: 26px auto;
                transform: scaleY(-1);
            }
        }
    }

    &.collapsable {
        .btn-alt {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: center;
        }

        &:not(.collapsed) {
            .btn-alt {
                position: relative;
                padding: 0 15px 15px;
            }
        }
    }

    &.collapsed {
        max-height: 650px;
        padding-bottom: 4px;
        overflow: hidden;

        &::after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 100px;
            background: linear-gradient(to bottom, transparent, var(--white));
        }

        .btn-alt .label::after {
            transform: scaleY(1);
        }
    }
}
