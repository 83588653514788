@import "slider_advices.less";
@import "slider_brands.less";
@import "slider_products.less";
@import "slider_inner_links.less";
@import "slider_multi_banners.less";

.splide {
    
    &__wrapper {
        margin: 20px 0;

        .banner__header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-basis: 100%;
            margin-bottom: 16px;
            position: relative;

            .inner_header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                &-link {
                    text-align: right;
                    color: var(--dark-grey);
                    margin-left: auto;
                    margin-right: 35px;
                }
            }
        }

        .banner__header-title{
            flex: 1;

            &.section-title,
            .section-title {
                margin-bottom: 0;
            }
        }

        .banner__header-link {
            text-align: right;
            color: var(--dark-grey);
            margin-left: auto;
            margin-right: 35px;
        }
    }

    &__list {
        height: unset;
    }

    &__slide {
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color: var(--white);

        /* stylelint-disable-next-line */
        img {
            width: 100%;
        }

        &.is-loading {
            padding-top: 192px;

            .splide__spinner {
                top: -100px;
            }
        }
    }

    &__track {
        padding: 4px;

        &--shadow {
            .splide__slide {
                box-shadow: 0 1px 6px 0 rgba(69, 69, 69, .24);
            }
        }
    }

    &__arrows {
        position: absolute;
        top: 50%;
        left: -8px;
        right: -8px;
        padding: 0 5px;
        transform: translateY(-50%);
        z-index: 10;
        display: flex;
        pointer-events: none;
        transition: opacity .3s ease-in-out;
    }

    &__arrow {
        top: calc(~"50% + 24px");
        background: var(--white);
        border: none;
        cursor: pointer;
        pointer-events: all;
        opacity: 1;
        transition: opacity .3s ease-in-out, 150ms box-shadow ease-in-out;
        outline: none;
        border-radius: 50%;
        padding: 7px;
        height: 32px;
        width: 32px;
        box-shadow: 0 0 2px 0 rgba(53, 53, 53, .16), 0 3px 6px 0 rgba(53, 53, 53, .16);

        &:disabled {
            pointer-events: none;
            opacity: 0;
        }

        &:hover {
            background-color: #f8f8f8;
            box-shadow: 0 0 0 0 rgba(53, 53, 53, .16), 0 3px 3px 0 rgba(53, 53, 53, .16);
        }

        &--prev {
            transform: scaleX(-1);
        }

        &--next {
            margin-left: auto;
        }

        /* stylelint-disable-next-line */
        svg {
            fill: var(--blue);
            height: 100%;
            width: 100%;
        }
    }

    &.gradient-cut:has(.splide__arrow--prev:not(:disabled))::before{
        content:"";
        display: block;
        width: 50px;
        height: 100%;
        background: linear-gradient(to left, rgba(204, 223, 249, 0), rgb(204, 223, 249, 1));
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
    }

    &.gradient-cut:has(.splide__arrow--next:not(:disabled))::after{
        content:"";
        display: block;
        width: 50px;
        height: 100%;
        background: linear-gradient(to right, rgba(204, 223, 249, 0), rgb(204, 223, 249, 1));
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .splide__pagination {
        position: absolute;
        bottom: 16px;
        left: 50%;

        /* Librairie ne dispose pas de classe sur le LI */

        /* stylelint-disable-next-line */
        li {
            margin-right: 8px;
        }

        &__page {
            border-radius: 50%;
            background-color: var(--low-grey);
            padding: 4px;
            border: none;

            &.is-active {
                background-color: var(--blue);
            }
        }
    }
    
}
