.reinsurances {
    padding: 20px 20px 25px;
    background-color: var(--dark-grey);
    color: var(--white);

    .text {
        text-align: center;
        font-size: 14px;
        color: inherit;
    }

    .list {
        max-width: 980px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: baseline;
        margin: 30px auto 0;
    }

    .item {
        width: 140px;
        font-family: var(--font-bold);
        font-size: 12px;
        line-height: 1.2;
        color: inherit;
        text-align: center;

        &.pointer {
            cursor: pointer;
        }

        .link {
            text-decoration: none;
            color: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .picture {
        max-height: 35px;
        width: auto;

        &_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 68px;
            width: 68px;
            border-radius: 68px;
            background-color: var(--white);
            margin: 0 auto 10px;
        }
    }
}
