.geo {
    &-form {
        display: flex;
        flex-flow: row nowrap;
        height: 48px;
        border: solid 1px var(--dark-grey);
        border-radius: 3px;
    }

    &-input {
        width: 100%;
        padding: 15px 16px;
        border: none;
        outline: none;
        background: var(--white);
        font-family: var(--font-regular);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &-button {
        flex: none;
        width: 48px;
        margin: 0;
        border: none;
        color: var(--white);
        background: var(--dark-grey);
        cursor: pointer;
    }

    &-suggestions {
        position: absolute;
        width: 100%;
    }

    &-list {
        width: calc(~"100% - 44px");
        max-height: 168px;
        background: var(--white);
        border-radius: 3px;
        box-shadow: 0 0 6px rgba(69, 69, 69, .4);
        overflow-y: auto;
    }

    &-item {
        font-family: var(--font-bold);
        font-size: 14px;
        padding: 19px 16px;

        &:hover,
        &:focus,
        &.js-active {
            background: var(--light-grey);
            cursor: pointer;
        }
    }
}