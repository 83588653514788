.bloc-advices {
    display: flex;
    flex-direction: column;
    text-align: center;
    
    &.splide {
        visibility: visible;
    }
    
    .splide__slide {
        padding: 16px;
    }

    .article {
        display: flex;
        flex-direction: column;
        height: 100%;

        &:focus .text-link,
        &:hover .text-link {
            text-decoration: underline;
        }
    }

    .picture {
        display: block;
        width: 100%;
        height: auto;
        margin-bottom: 8px;
    }

    .theme {
        display: block;
        margin-bottom: 8px;
        font-family: var(--font-bold);
        font-size: 8px;
        letter-spacing: 4px;
        text-transform: uppercase;
    }

    .title {
        font-family: var(--font-bold);
        line-height: 1.3;
    }

    .text-link {
        align-self: end;
        margin-top: auto;
        padding-top: 18px;
        font-size: 12px;
        line-height: 1.125;
        color: var(--blue);
        text-decoration: none;
    }

    .link {
        text-decoration: none;
        color: var(--dark-grey);
        outline: none;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
        }

        &:focus-visible::before {
            outline: 2px solid var(--black);
        }
    }
}
