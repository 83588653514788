.list-item-with-blue-bulletpoint {
    position: relative;

    &::before {
        content: "";
        background-color: var(--blue);
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 100%;
        position: absolute;
        top: 5px;
        left: 0;
    }
}

.list-item {
    &::before {
        content: "";
        width: 4px;
        height: 4px;
        border-radius: 100%;
        position: absolute;
        left: 0;
        margin-right: 8px;
        background-color: var(--soft-grey);
        top: 5px;
    }
}
