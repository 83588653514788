.body {
    &:not(.new-fonts) {
        --font-regular: @openSans;
        --font-bold: @openSansBold;
        --font-bold-alt: @openSansBold;
    }

    &.new-fonts {
        --font-regular: @manrope;
        --font-bold: @manropeBold;
        --font-bold-alt: @partyBold;
    }
    font-family: var(--font-regular);
    font-size: 16px;
    background-color: var(--light-grey);
    color: var(--dark-grey);
}

.site-wrapper {
    position: relative;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 16px;
    width: 1024px;

    @media @desktop { width: 1280px; }

    @media @desktopXL { width: 1600px; }

    &.back-office-active {
        padding-top: 39px;
    }
}
