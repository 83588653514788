.buybox-flag {
    display: inline-block;
    margin-top: 20px;
    font-size: 12px;

    .bold {
        font-family: var(--font-bold);
    }

    .wrapper {
        padding: 3px;
        border-radius: 3px;
        color: var(--white);
        text-transform: uppercase;
        margin-bottom: 6px;
    }

    &.is-discount .wrapper {
        background-color: var(--red);
    }

    &.is-preorder .wrapper {
        background-color: var(--orange);
    }
}
