.bloc-brands {
    .link {
        margin: auto;
        padding: 20px;
    }

    .image {
        display: block;
        max-height: 71px;

        &.predefined {
            aspect-ratio: 16 / 9;
        }
    }
}
