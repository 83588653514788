.breadcrumb {
    margin: 0 0 16px;
    padding: 0;

    .list-nodes {
        display: flex;
        align-items: center;
        list-style: none;

        .node {
            font-size: 12px;
            line-height: 13px;
            color: var(--grey);

            .node-link {
                text-decoration: underline;
                color: var(--grey);
            }

            &:not(:last-child) {
                .node-link {
                    margin-right: 3px;
                }
            }
        }
    }
}

.products_list {
    .breadcrumb {
        padding-top: 15px;
    }
}
