.custom-input {
    position: relative;

    &__label {
        position: absolute;
        top: 20px;
        left: 16px;
        font-size: 14px;
        transition: 100ms all ease-out;
    }

    &__error-message {
        font-family: var(--font-bold);
        color: var(--red);
        font-size: 12px;
        padding: 8px;

        &:empty {
            padding: 0;
        }
    }

    &__input {
        padding: 18px 16px;
        width: 100%;
        font-size: 16px;
        border: 1px solid var(--low-grey);
        border-radius: 3px;
        box-sizing: border-box;

        &--has-content,
        &:focus {
            + .custom-input__label {
                font-size: 12px;
                top: 7px;
            }
        }
    }

    &--is-error .custom-input__input {
        border-color: var(--red);
    }

    &__status {
        display: none;
        background-repeat: no-repeat;
        background-size: 8px;
        background-position: center;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        position: absolute;
        right: 18px;
        top: 20px;

        &--is-valid {
            display: block;
            background-image: @picto_check;
            background-color: var(--green);
        }

        &--is-error {
            display: block;
            background-image: @picto_close_white;
            background-color: var(--red);
        }
    }
}
