.button-add-basket {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    color: var(--white);
    font-size: 24px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    padding: 0;

    &.basket {
        background-color: var(--red);

        &:hover {
            background-color: var(--red-hover);
        }
    }

    &.basket-cc {
        background-color: var(--dark-grey);

        &:hover {
            background-color: var(--black-hover);
        }
    }

    &.btn-list {
        font-family: var(--font-bold);
        width: auto;
        font-size: 16px;
        line-height: 1;
        display: flex;
        flex-flow: row nowrap;
        white-space: nowrap;
        gap: 8px;
    }
}

.btn-reset {
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
}

.cta {
    display: block;
    border: solid 1px var(--blue);
    background-color: var(--blue);
    padding: 11px 24px;
    font-family: var(--font-bold);
    font-size: 14px;
    line-height: 18px;
    border-radius: 3px;
    color: var(--white);
    text-decoration: none;
    text-align: center;
    cursor: pointer;

    &.blue {
        border-color: var(--blue);
        background-color: var(--blue);
        color: var(--white);

        &:hover {
            background-color: var(--light-blue-hover);
        }
    }

    &.grey {
        border-color: var(--light-grey);
        background-color: var(--light-grey);
        color: var(--blue);

        &:hover {
            background-color: var(--light-grey); /* darken */
        }
    }

    &.white {
        border-color: var(--blue);
        background-color: transparent;
        color: var(--blue);

        &:hover {
            background-color: var(--light-blue-hover);
        }
    }

    &.purple {
        border-color: var(--purple);
        background-color: var(--white);
        color: var(--purple);
    }

    &.spacing {
        margin: 24px 0 16px;
    }

    &.inline {
        display: inline-block;
    }
}

.button-add-devis {
    width: 100%;
    margin-top: 8px;
    justify-content: center;
    align-items: center;

    &::before {
        content: "";
        display: inline-block;
        width: 18px;
        height: 24px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPjxkZWZzPjxwYXRoIGQ9Ik0xNi4wNiAwYy44NTEgMCAxLjU1My43MDIgMS41NTMgMS41NTR2MjAuNzIyYzAgLjg1Mi0uNzAyIDEuNTU0LTEuNTU0IDEuNTU0SDEuNTU0QTEuNTYyIDEuNTYyIDAgMCAxIDAgMjIuMjc2VjEuNTU0QzAgLjcwMi43MDIgMCAxLjU1NCAwWm0tMi4wNzMgMTguMzlIMy42MjdhLjUxOC41MTggMCAxIDAgMCAxLjAzN2gxMC4zNmEuNTE4LjUxOCAwIDEgMCAwLTEuMDM2Wm0wLTQuNjYySDMuNjI3YS41MTguNTE4IDAgMSAwIDAgMS4wMzZoMTAuMzZhLjUxOC41MTggMCAxIDAgMC0xLjAzNlptMC00LjY2MkgzLjYyN2EuNTE4LjUxOCAwIDEgMCAwIDEuMDM2aDEwLjM2YS41MTguNTE4IDAgMSAwIDAtMS4wMzZabTAtNC42NjNIMy42MjdhLjUxOC41MTggMCAxIDAgMCAxLjAzNmgxMC4zNmEuNTE4LjUxOCAwIDEgMCAwLTEuMDM2WiIgaWQ9ImEiLz48L2RlZnM+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLjg2MSAuMDcpIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxtYXNrIGlkPSJiIiBmaWxsPSIjNjYyZDkxIj48dXNlIHhsaW5rOmhyZWY9IiNhIi8+PC9tYXNrPjx1c2UgZmlsbD0iIzY2MmQ5MSIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPjxnIG1hc2s9InVybCgjYikiIGZpbGw9IiNmZmZmZmYiPjxwYXRoIGQ9Ik0tMi44NjEtLjA3aDI0djI0aC0yNHoiLz48L2c+PC9nPjwvc3ZnPg==);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
          
    }

    &.btn-list {
        font-family: var(--font-bold);
        font-size: 16px;
        line-height: 1;
        display: flex;
        flex-flow: row nowrap;
        white-space: nowrap;
        gap: 8px;
    }

    &.added {
        &::before {
            width: 18px;
            height: 24px;
            background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQuNjc1IDcuMzYyIDEuNTQ3IDQuMTMzYS44OTMuODkzIDAgMCAwLTEuMjgtLjAwMi45NDQuOTQ0IDAgMCAwLS4wMDMgMS4zMTdMNC42NzUgMTBsOC4wNjItOC40MTFBLjk1Ljk1IDAgMCAwIDEyLjczNC4yN2EuODgyLjg4MiAwIDAgMC0xLjI3LjAwM0w0LjY3NSA3LjM2MnoiIGZpbGw9IiM2NjJkOTEiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
        }
    }
}
