.cta-content {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    pointer-events: none;

    &.icon-right {
        flex-direction: row-reverse;
    }
}

.cta-clean {
    display: inline-block;
    height: 48px;
    padding: 11px;
    border-radius: 50em;
    font-family: var(--font-bold);
    font-size: 16px;
    line-height: 20px;
    color: var(--white);
    text-decoration: none;
    border: none;
    cursor: pointer;

    &.aap {
        .button-states(var(--red), var(--red-hover), var(--red-pressed));
    }

    &.cc {
        .button-states(var(--black), var(--black-hover), var(--black-pressed));
    }

    &.primary {
        .button-states(var(--blue), var(--blue-hover), var(--blue-pressed));
    }

    &.secondary {
        padding: 11px;
        border: solid 1px var(--blue);
        color: var(--blue);
        .button-states(transparent, var(--white-hover), var(--white-pressed));
    }

    &.tertiary {
        color: var(--blue);
        .button-states(var(--light-grey), var(--white-hover), var(--white-pressed));
    }

    &.tertiary-alt {
        color: var(--blue);
        .button-states(transparent, var(--white-hover), var(--white-pressed));

        &.disabled {
            .button-states(transparent, transparent, transparent);
        }
    }

    &:disabled {
        padding: 12px;
        background-color: var(--disabled);
        color: var(--text-disabled);
        cursor: not-allowed;
        border: none;
    }

    &.round {
        width: fit-content;
    }

    &.small {
        width: 32px;
        height: 32px;
        padding: 6px;
    }

    &.underline {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    &.fullwidth {
        width: 100%;
    }

    &.button-margin {
        margin: 16px;
    }

    &.button-padding {
        padding: 14px 24px;
    }

    &.center {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.height-auto {
        height: auto;
    }

    &.small-size {
        font-size: 14px;
        line-height: 18px;
    }

    // Css surchargagé par le composant
    &.sticky-clear {
        background-color: transparent;
        border: solid 1px var(--white);
    }

    &.btn-product-instruction {
        width: 279px;
        margin-top: 22px;
    }

    &.reviews_trigger {
        margin: 40px auto 0;
        width: 248px;

        &.ald {
            width: 262px;
        }

        &::after {
            content: url(../../styles/images/pictos/chevron-right-blue.svg);
            width: 20px;
            height: 20px;
            margin-left: 4px;
        }
    }

    &.sticky {
        width: 202px;
    }

    &.view_pack_btn {
        width: 140px;
    }
    
    &.btn-buy {
        margin-left: 10px;
    }

    &.btn-cancel {
        width: 160px;
    }
}

// Mixin des états du cta : normal, hover, focus/active
.button-states(@bg-color, @hover-color, @pressed-color) {
    background-color: @bg-color;

    &:hover {
        background-color: @hover-color;
    }

    &:active {
        background-color: @pressed-color;
    }
}
