.secure_payment {
    display: flex;
    flex-flow: row nowrap;
    margin: 0 auto;
    padding: 16px 0;
    justify-content: center;
    align-items: center;
    border-bottom: solid 1px var(--border-grey);

    .title {
        font-size: 14px;
        text-transform: uppercase;
        color: var(--dark-grey);
        margin-right: 17px;

        &::before {
            content: "";
            height: 15px;
            width: 14px;
            display: inline-block;
            margin-right: 12px;
            background-image: @picto_lock;
        }
    }

    .list {
        width: 488px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        vertical-align: middle;
    }

    .item {
        height: 28px;
        width: 41px;
        display: flex;

        .applePay {
            width: 32px;
            height: auto;
            margin: auto;
        }
    }

    .more {
        font-family: var(--font-bold);
        font-size: 13px;
        color: inherit;
        text-decoration: underline;
        white-space: nowrap;
        margin-left: 14px;
    }
}
