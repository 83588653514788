/****************************************
  * Header
  ***************************************/
.theme-dpro {
    .surheader {
        background: var(--purple);
    }

    /* Menu */
    .user-menu {
        grid-template-columns: repeat(4, 1fr);

        .item {
            width: 133px;
        }

        .login {
            &-link {
                color: var(--blue);

                &.icon-logout {
                    color: var(--black);
                }
            }
        }

        .button,
        .link {
            &.js-dmax {
                &::before {
                    background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48ZyBmaWxsPSIjMDA1RUFEIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgyLjUgMikiPjxwYXRoIGQ9Ik0xMC4wMyAxMC45NDJjNS4zNzIgMCA5LjcyNyAxLjgzOCA5LjcyNyA4LjEwNSAwIC41NS0uMDM0IDEuMDkyLS4wOTkgMS42MjJILjQwMmExMy4zMiAxMy4zMiAwIDAgMS0uMDk4LTEuNjIyYzAtNi4yNjcgNC4zNTUtOC4xMDUgOS43MjYtOC4xMDVaIi8+PGNpcmNsZSBjeD0iOS43MjYiIGN5PSI0Ljg2MyIgcj0iNC44NjMiLz48L2c+PHBhdGggZD0iTTE4IDIzaDRjMSAwIC41LTIuNDU3LjUtNCAwLTMuMDM4LTEuOTYyLTYtNS02LTMuMDM4IDAtNiAyLjk2Mi02IDYgMCAxLjQzNS4xIDIuNTIgMSAzLjUgMS4wMDUgMS4wOTMgMy44OTguNSA1LjUuNVoiIGZpbGw9IiNGRkYiLz48cGF0aCBkPSJNMzAuMDI3IDE2LjE2MmE0LjE3IDQuMTcgMCAwIDAtNS43ODMuMDA3bC0xLjg4NSAxLjg1MS0uMjM1LjIzLS43NzcuNzY0LS4wMDguMDA3LTEuODc4IDEuODQ1Yy0uOTgyLjk4Ny0yLjcyNi45OTYtMy43MjItLjAwN2EyLjYxOSAyLjYxOSAwIDAgMS0uNzI3LTEuMjg1bC0uNzk0LS41MS0uNjc2LjM3NWEzLjk2MiAzLjk2MiAwIDAgMCAxLjE3NyAyLjQwOGMxLjUyNiAxLjU0IDQuMjUgMS41MzIgNS43NjIuMDA3bDEuODc5LTEuODQ1LjgxMy0uOC4xOTItLjE4OC4wMDctLjAwNiAxLjg3OC0xLjg0NWEyLjcyMiAyLjcyMiAwIDAgMSAzLjc2NC0uMDEzYy40My40MzUuNjgxLjk3Mi43NTIgMS41MjZsLjc1OC0uNDIuNjg0LjQzOWE0LjA1MSA0LjA1MSAwIDAgMC0xLjE4MS0yLjU0IiBmaWxsPSIjMDA1RUFEIi8+PHBhdGggZD0ibTIwLjUzOCAxOC42MDYgMS0xLjA3LjAwNC0uMDA0LS40OTctLjU0Mi0uNzA2LS43N2MtMS40OS0xLjYyNi00LjEzOS0xLjYyNy01LjYzMiAwYTQuNTMgNC41MyAwIDAgMC0xLjE2NSAyLjcwNWwuNjk0LS40MjguNzA1LjUwM2EyLjk1NCAyLjk1NCAwIDAgMSAuNzU4LTEuNjk4IDIuNDYxIDIuNDYxIDAgMCAxIDMuNjQ5IDBsMS4xOSAxLjMwNFoiIGZpbGw9IiNGRkRFMTciLz48cGF0aCBkPSJtMzAuMTg4IDE5LS43NDUuNDJjLS4wOTIuNTA1LS4zMy45ODktLjcxOSAxLjM4NmEyLjYxNiAyLjYxNiAwIDAgMS0zLjczIDBsLS45MTMtLjkxLS4yLS4xOTgtLjAwMy4wMDQtMS4wMDMgMSAuMjU5LjI1N2guMDA0bC4wMDUuMDA0Ljg0Ni44NDRhNC4wNDkgNC4wNDkgMCAwIDAgNi44ODYtMi4zNkwzMC4xODggMTlaIiBmaWxsPSIjRUQxQzFEIi8+PC9nPjwvc3ZnPg==);
                }
            }

            &.devis {
                &::before {
                    width: 18px;
                    height: 24px;
                    background: url(../../version_common/styles/images/pictos/devis.svg);
                }
            }
        }

        .label {
            &::after {
                background-color: var(--purple);
            }
        }
    }

    .footer_extras {
        grid-template-rows: unset;
    }

    .comparator-component {
        background-color: var(--purple);
    }

    .comparator .wrapper::before {
        background-color: var(--purple);
    }
}
