.slider_durability {
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    gap: 16px;

    .btn-reset {
        margin: 0;
        border: 0;
        padding: 0;
        background: none;
        cursor: pointer;
    }

    &.has_button_product_info {
        margin-bottom: 8px;
    }
}

.slider_flags {
    .splide__list {
        align-items: center;
    }

    .flag {
        height: 40px;
        object-fit: contain;
    }
}

.reparability_popin {
    .main {
        max-height: unset;
        height: 400px;
    }

    .picture {
        width: 100%;
        height: auto;
    }
}

.energy_flag {
    height: 40px;

    .index {
        display: none;

        &.active {
            display: block;
        }

        &_A {
            fill: #00a453;
        }

        &_B {
            fill: #55b64e;
        }

        &_C {
            fill: #bfd541;
        }

        &_D {
            fill: #fff02a;
        }

        &_E {
            fill: #fcb731;
        }

        &_F {
            fill: #f17030;
        }

        &_G {
            fill: #eb212e;
        }
    }

    .index_old {
        &_A {
            fill: #fff02a;
        }

        &_A1 {
            fill: #bed630;
        }

        &_A2 {
            fill: #55b64e;
        }

        &_A3 {
            fill: #00a550;
        }

        &_B {
            fill: #fcb731;
        }

        &_C {
            fill: #f26f21;
        }

        &_D {
            fill: #eb212e;
        }
    }

    &.js-fsi-enable {
        cursor: pointer;
    }
}

.reparability_flag {
    height: 64px;

    .unit,
    .decimal {
        display: none;

        &.active {
            display: block;
        }
    }

    &.index {
        &_1 {
            color: #d91627;
        }

        &_2 {
            color: #fe741b;
        }

        &_3 {
            color: #ffc901;
        }

        &_4 {
            color: #9ecc00;
        }

        &_5 {
            color: #009443;
        }
    }
}

.universal_charger {
    display: flex;
    flex-flow: row nowrap;
    gap: 16px;
}