.seo {
    column-count: 3;
    margin: 0 auto;
    padding: 24px 16px 35px;

    @media @desktop {
        column-count: unset;
        display: grid;
        grid-auto-flow: column;
        grid-template-columns: repeat(4, 1fr);
    }

    .list {
        break-inside: avoid;
        margin-bottom: 25px;
    }

    .item {
        margin-bottom: 4px;

        &.title {
            font-family: var(--font-bold);
            font-size: 14px;
            text-transform: uppercase;
            margin-bottom: 14px;
        }
    }

    .element {
        font-family: var(--font-regular);
        color: inherit;
        font-size: 14px;
        line-height: 1.6;
        text-decoration: none;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }

        &.faciliti {
            display: flex;
            align-items: center;
        }

        .facilitilogo {
            margin-right: 4px;
        }
    }
}
