.suggestions {
    position: absolute;
    width: 100%;

    .suggestions_list {
        width: calc(~"100% - 44px");
        border-radius: 3px;
        background-color: var(--white);
        max-height: 168px;
        box-shadow: 0 0 6px 0 rgba(69, 69, 69, .4);
        overflow-y: auto;

        &--item {
            font-family: var(--font-bold);
            position: relative;
            font-size: 14px;
            padding: 19px 16px;

            &::after {
                content: "";
                position: absolute;
                left: 50%;
                bottom: 0;
                transform: translateX(-50%);
                width: calc(~"100% - 32px");
                height: 1px;
                background-color: var(--border-grey);
            }

            &:last-child::after {
                content: none;
            }

            &:hover,
            &:focus,
            &.active {
                background-color: var(--light-grey);
                cursor: pointer;
            }
        }
    }
}

.suggestion_hidden {
    display: none;
}

.suggestion_visible {
    display: block;
}
