.toast {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 16px;
    position: fixed;
    bottom: -80px;
    left: 16px;
    padding: 14px 16px;
    background-color: var(--white);
    border-radius: 3px;
    opacity: 0;
    transition: 1s opacity ease-out, 1s bottom ease-out;
    z-index: 12;

    &.js-active {
        bottom: 80px;
        opacity: 1;
    }

    .wrapper {
        display: flex;
        align-items: center;

        &::before {
            display: block;
            height: 20px;
            width: 20px;
            background-repeat: no-repeat;
            background-position: 50% 48%;
            background-size: 50%;
            border-radius: 20px;
            margin-right: 12px;
        }
    }

    .close {
        display: block;
        height: 12px;
        width: 12px;
        margin-left: auto;
        background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cGF0aCBkPSJNMS41MDMuMjM2IDYgNC43MzIgMTAuNDk3LjIzNmEuODA2LjgwNiAwIDAgMSAxLjE0IDBsLjEyNy4xMjdhLjgwNi44MDYgMCAwIDEgMCAxLjE0TDcuMjY3IDZsNC40OTcgNC40OTdhLjgwNi44MDYgMCAwIDEgMCAxLjE0bC0uMTI3LjEyN2EuODA2LjgwNiAwIDAgMS0xLjE0IDBMNiA3LjI2N2wtNC40OTcgNC40OTdhLjgwNi44MDYgMCAwIDEtMS4xNCAwbC0uMTI3LS4xMjdhLjgwNi44MDYgMCAwIDEgMC0xLjE0TDQuNzMyIDYgLjIzNiAxLjUwM2EuODA2LjgwNiAwIDAgMSAwLTEuMTRMLjM2My4yMzZhLjgwNi44MDYgMCAwIDEgMS4xNCAwWiIgZmlsbD0iIzM1MzUzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+Cjwvc3ZnPgo=) no-repeat 50% 50% transparent;
        border: none;
        cursor: pointer;

        &::before {
            content: "";
            position: absolute;
            inset: 0;
        }
    }

    &.success .wrapper::before {
        content: "";
        background-image: url(../../../version_common/styles/images/pictos/success_icon.svg);
        background-color: var(--green);
    }

    &.error .wrapper::before {
        content: "";
        background-image: url(../../../version_common/styles/images/pictos/picto_close_white.svg);
        background-color: var(--red);
    }
}
