@import "../components/product/durability.less";

.bloc-products {
    margin: 20px auto;

    .form-add-basket {
        z-index: 1;
    }

    .banner-products {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;

        &__header-wrapper {
            position: relative;
            display: flex;
            flex-basis: 100%;

            .banner-products__header {  
                width: 100%;

                .inner_header {
                    display: flex;
                    align-items: end;

                    .banner-products__header-link {
                        text-align: right;
                        color: var(--dark-grey);
                        margin-left: auto;
                        margin-right: 35px;
                    }
                }
            }
        }

        .zone-promotional {
            position: relative;
            width: 614px;
            height: 424px;

            @media @desktop {
                width: 514px;
            }

            @media @desktopXL {
                width: 875px;
            }

            .promotional-bg {
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }

        .zone-products {
            width: 516px;
            margin-left: -140px;

            @media @desktop {
                margin-left: -44px;
                width: 778px;
            }

            @media @desktopXL {
                width: 778px;
                margin-left: -85px;
            }
        }
    }

    .energy_flag {
        height: 24px;

        .index {
            display: none;

            &.active {
                display: block;
            }

            &_A {
                fill: #00a453;
            }

            &_B {
                fill: #55b64e;
            }

            &_C {
                fill: #bfd541;
            }

            &_D {
                fill: #fff02a;
            }

            &_E {
                fill: #fcb731;
            }

            &_F {
                fill: #f17030;
            }

            &_G {
                fill: #eb212e;
            }
        }

        .index_old {
            &_A {
                fill: #fff02a;
            }

            &_A1 {
                fill: #bed630;
            }

            &_A2 {
                fill: #55b64e;
            }

            &_A3 {
                fill: #00a550;
            }

            &_B {
                fill: #fcb731;
            }

            &_C {
                fill: #f26f21;
            }

            &_D {
                fill: #eb212e;
            }
        }
    }

    .product {
        display: grid;
        grid-template-rows: 145px 73px 20px 48px 26px;
        padding: 26px 16px 16px;
        text-align: left;
        text-decoration: inherit;

        .visual {
            grid-area: 1 / 1 / 2 / 2;
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            text-decoration: none;

            .visual-bottom-flags {
                position: absolute;
                display: flex;
                left: 0;
                right: 0;
                bottom: 0;
                justify-content: space-between;
                align-items: flex-start;

                .reparability_flag {
                    height: 29px;
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
            }
        }

        .image {
            display: block;
            object-fit: contain;
            max-width: 100%;
            max-height: 100%;
            margin: auto;
            text-decoration: none;
        }

        .picto {
            width: auto;
        }

        .energy_flag {
            height: 24px;
            width: auto;
        }

        .details {
            grid-area: 2 / 1 / 3 / 2;
            margin-top: 8px;
        }

        .ellipsis {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
        }

        .category {
            font-size: 12px;
            color: var(--mid-grey);
            line-height: 16px;
        }

        .name {
            display: block;
            font-family: var(--font-bold);
            font-size: 14px;
            line-height: 16px;
            color: var(--dark-grey);
            text-decoration: none;
            text-transform: uppercase;

            &.ellipsis-2lines {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        .name::before {
            z-index: 1;
            content: "";
            position: absolute;
            inset: 0;
        }

        .reviews {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            font-size: 12px;
            line-height: 13px;
            color: var(--mid-grey);
            margin-top: 4px;

            &::before {
                content: "";
                background: @picto_stars;
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                display: block;
                height: 12px;
                width: 12px;
                margin: 0 4px auto 0;
            }
        }

        .more {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }

        .prices {
            &_old {
                display: flex;
                gap: 8px;
                margin-top: 4px;
            }
        }

        .price {
            font-family: var(--font-bold);
            font-size: 24px;
            line-height: 1.3;

            &_promo {
                color: var(--red);
            }
        }

        .striped_price {
            font-size: 10px;
            color: var(--mid-grey);
            text-decoration: line-through;
        }

        .unavailability {
            grid-area: 5 / 1 / 6 / 2;
            font-family: var(--font-bold);
            font-size: 12px;
            line-height: 17px;
        }

        .seller {
            grid-area: 5 / 1 / 6 / 2;

            .seller-origin {
                display: flex;
                gap: 4px;
                align-items: center;

                .flag-seller {
                    width: unset;
                }
            }

            .seller-label {
                display: flex;
                align-items: center;
                font-size: 12px;
                line-height: 17px;
                color: var(--marketplace);
    
                .picto {
                    object-fit: cover;
                }
            }

            &.no-flag-seller {
                padding-top: 16px;
            }
        }

        .picto-fnac-seconde-vie {
            width: 72px;
            height: 14.5px;
        }

        .picto-darty-scndvie {
            width: 119px;
            height: auto;
        }

        .mkp-seller-label&::before {
            content: "";
            display: inline-block;
            background-image: url(../../../version_common/styles/images/pictos/blue-tag.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            vertical-align: middle;
            width: 13px;
            height: 13px;
            margin-right: 2px;
        }

        .seller-name {
            font-family: var(--font-bold);
        }

        .bottom-modality {
            position: absolute;
            bottom: 17px;
            color: var(--mid-grey);
            font-size: 10px;
        }
    }

    .top-flags {
        position: absolute;
        left: 0;
        top: -10px;
        width: 100%;

        .flag-sponsored {
            font-size: 8px;
            line-height: 10px;
            color: var(--mid-grey);
        }

        .flag_odr {
            font-size: 12px;
            border: 1px solid var(--low-grey);
            border-radius: 8px;
            padding: 3px 4px 3px 8px;
            font-family: var(--font-bold-alt);
            text-transform: uppercase;
            background-color: var(--white);
            position: absolute;
            left: -16px;
            top: -12px;

            &::before {
                content: "";
                display: inline-block;
                background-image: url(../../../version_common/styles/images/pictos/pictoEco.svg);
                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                vertical-align: bottom;
                height: 13px;
                width: 13px;
                margin-right: 4px;
            }
        }
    }

    .bottom-flags {
        grid-area: 3 / 1 / 4 / 2;
        width: fit-content;

        .flag {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--white);
            background: var(--red);
            border-radius: 3px;
            padding: 0 2px;

            &.pre-order {
                background-color: var(--orange);
            }
        }

        .flag-value {
            font-family: var(--font-bold);
        }
    }

    &.bloc-products-fa .banner-products__header {
        display: none;
    }

    [data-tooltip],
    .tooltip-wrap {
        &::after {
            top: initial;
            bottom: 24px;
        }

        .tooltip,
        &::before {
            left: 120px;
        }

        .short-notice {
            color: var(--mid-grey);
            font-size: 10px;
            line-height: 16px;
            text-align: right;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-top: 0;
            white-space: nowrap;
            
            .image-legal-mention {
                margin: 0 0 0 5px;
            }
        }
    }

    .short-notice-with-tooltip {
        position: relative;
        z-index: 50;
    }
}