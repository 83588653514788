.footer_extras {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 90px;
    grid-gap: 24px 40px;
    color: var(--white);
    background-color: var(--dark-grey);
    padding: 32px 16px;

    /* ces liens proviennent de la structure html renvoyée depuis le mini-bo, pas de classe dessus */

    /* stylelint-disable-next-line */
    a {
        color: inherit;
    }

    .bloc {
        position: relative;
        font-size: 13px;
        line-height: 1.2;
        padding-left: 63px;

        &.stores {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 44px;
                display: inline-block;
                background: @picto_store_black_and_white center left no-repeat;
            }

            .searchbar {
                margin-top: 16px;
            }

            .input {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }

            .submit {
                background-color: var(--blue);
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }

        &.apps {
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 17px;
                height: 44px;
                width: 46px;
                display: inline-block;
                background: @picto_smartphone center left no-repeat;
            }
        }
    }

    .title {
        display: block;
        text-transform: uppercase;
        font-family: var(--font-bold);
        font-size: 15px;
        margin-bottom: 8px;
    }

    .list {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: fit-content;
        margin-top: 16px;
    }

    .logo {
        display: block;
        height: 40px;
        width: auto;
    }

    .discover {
        .wrapper {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .link {
            margin-right: 16px;
        }

        .logo {
            padding: 8px;
            background-color: var(--white);
            border-radius: 3px;
            display: block;
            height: auto;
        }

        &:not(.bloc) {
            margin-top: 22px;
        }
    }

    .apps {
        .item {
            margin-right: 16px;

            @media @desktop { margin-right: 40px; }

            @media @desktopXL { margin-right: 65px; }
        }

        .list {
            margin-top: 28px;

            @media @desktopXL { margin-top: 16px; }
        }
    }

    .socials {
        .item {
            margin-right: 64px;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
