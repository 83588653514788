.legal {
    &_notice {
        width: 816px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        margin: 0 auto;
        padding: 16px 0;

        .logo {
            margin-right: 8px;

            .picto {
                height: 32px;
                width: auto;
            }
        }
    }

    &_item {
        font-family: var(--font-bold);
        font-size: 13px;
        color: inherit;
        background-color: transparent;
        margin-right: 16px;
        border: none;
        text-decoration: underline;

        &:last-child {
            margin-right: 0;
        }

        &.ot-sdk-show-settings {
            font-family: inherit !important; /* stylelint-disable-line declaration-no-important */
            font-size: 13px !important; /* stylelint-disable-line declaration-no-important */
            line-height: inherit !important; /* stylelint-disable-line declaration-no-important */
            padding: 0 !important; /* stylelint-disable-line declaration-no-important */
            border: none !important; /* stylelint-disable-line declaration-no-important */
            outline: none !important; /* stylelint-disable-line declaration-no-important */
            color: inherit !important; /* stylelint-disable-line declaration-no-important */
            transition: none !important; /* stylelint-disable-line declaration-no-important */

            &:hover {
                color: inherit !important; /* stylelint-disable-line declaration-no-important */
                background-color: transparent !important; /* stylelint-disable-line declaration-no-important */
            }
        }

        &:hover {
            text-decoration: underline;
        }
    }
}
