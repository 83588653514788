/* stylelint-disable selector-max-type */

@import "reset.css";
@import "variables/variables.less";
@import "layout.less";
@import "generic/generic.less";
@import "styles_dpro.less";
@import "components/breadcrumb.less";
@import "components/buttons_cta.less";
@import "../../version_common/styles/components/ctas.less";
@import "components/micromodal.less";
@import "components/autocomplete.less";
@import "components/splide.less";
@import "components/expand.less";
@import "components/comparator.less";
@import "components/custom-input.less";
@import "header_footer/footer.less";
@import "../../version_common/styles/service-icons.less";
@import "../../version_common/styles/flag.less";
@import "generic/geo.less";

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}
