.service-icon {
    display: flex;
    align-items: center;

    &::before {
        content: "";
        display: inline-block;
        height: 30px;
        width: 30px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        margin-right: 8px;
    }

    &.garantie::before {
        background-image: url(./images/pictos/darty_eco.svg);
    }

    &.livraison::before {
        background-image: url(./images/pictos/darty_delivery.svg);
    }

    &.retrait::before {
        background-image: url(./images/pictos/click_and_collect.svg);
    }

    &.conseils::before {
        background-image: url(./images/pictos/darty_idees.svg);
    }
}
