.codic-filter-dropdown,
.recommendation-filter-dropdown {
    margin-left: 20px;
    position: absolute;
    right: 0;

    &__trigger {
        width: 24px;
        height: 24px;
        background: transparent url(../../../version_common/design-system/icons/dots.svg) no-repeat center;
        color: var(--dark-grey);
        border: none;
        cursor: pointer;
    }

    &__wrapper {
        position: absolute;
        right: 0;
        top: 32px;
        z-index: 11;
        min-width: 260px;
        background-color: var(--white);
        box-shadow: 0 0 2px 0 var(--overlay-light), 0 5px 8px 0 var(--overlay-light), 0 2px 4px 0 var(--overlay-light);
        border-radius: 4px;
        visibility: hidden;
        transform: translateY(-32px);
        opacity: 0;
        transition: all ease-in-out 200ms;

        &.is-active {
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
        }
    }

    &__title {
        font-family: var(--font-bold);
        font-size: 14px;
        color: var(--dark-grey);
        padding: 16px;
    }

    &__list {
        padding-bottom: 8px;
        border-bottom: 1px solid var(--low-grey);
    }

    &__item {
        font-size: 14px;
        color: var(--dark-grey);
        background-color: var(--white);
    }

    &__check {
        font-family: var(--font-regular);
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 16px 16px 16px 40px;
        background: var(--white);
        border: none;
        cursor: pointer;

        &::before {
            content: "";
            width: 24px;
            height: 24px;
            background: url(../../../version_common/design-system/icons/check.svg) no-repeat center;
            color: var(--dark-grey);
            position: absolute;
            display: block;
            left: 8px;
            visibility: hidden;
            transform: translateX(24px) scale(0);
            opacity: 0;
            transition: all ease-in-out 100ms;
        }

        &:hover {
            background: var(--light-grey);
        }

        &.is-active {
            background: var(--light-grey);

            &::before {
                visibility: visible;
                transform: translateX(0) scale(100%);
                opacity: 1;
            }
        }
    }

    &__link {
        display: block;
        font-family: var(--font-regular);
        font-size: 14px;
        color: var(--dark-grey);
        padding: 16px;
    }
}
