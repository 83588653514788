.multi_banner {
    .bloc-custom-banner {
        margin: 0;
        height: 100%;

        .wrapper {
            height: 100%;
        }

        .image {
            height: auto;
            width: 100%;
            margin: auto;
        }
    }
}
